import { BACKEND_API_HOST, fetchApi, PageContext } from './utils';

export async function getStats(
    ctx: PageContext | null,
    clientType: ClientType,
): Promise<SimpleUsageStatisticsResponse> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/usageStatistics`,
        'GET',
        null,
        { clientType },
        false,
    );
}

export interface SimpleUsageStatisticsResponse {
    id: string;
    usersOnline: number;
    instancesOnline: number;
    xpGainedToday: number;
    levelsGainedToday: number;
    xpGainedThisMonth: number;
    levelsGainedThisMonth: number;
    xpGainedAllTime: number;
    levelsGainedAllTime: number;
    clientType: ClientType;
    createdAt: string;
}

export enum ClientType {
    MOBILE = 'MOBILE',
}
