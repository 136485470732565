import {
    ClientType,
    getStats,
    SimpleUsageStatisticsResponse,
} from '../../api/statsClient';
import { PageContext } from '../../api/utils';
import { apiRequestFailed } from './actions';

export const FETCH_STATS_REQUESTED = 'FETCH_STATS_REQUESTED';
export const FETCH_STATS_COMPLETED = 'FETCH_STATS_COMPLETED';

export const fetchStatsRequested = () => ({ type: FETCH_STATS_REQUESTED });
export const fetchStatsCompleted = (stats: SimpleUsageStatisticsResponse) => ({
    type: FETCH_STATS_COMPLETED,
    stats: stats,
});

export const fetchStats = (ctx: PageContext | null, clientType: ClientType) => {
    return async (dispatch) => {
        dispatch(fetchStatsRequested());
        try {
            const stats = await getStats(ctx, clientType);
            return dispatch(fetchStatsCompleted(stats));
        } catch (e) {
            dispatch(apiRequestFailed('Failed to fetch stats', e));
        }
    };
};
