import { PageContext, PaginatedResponse } from '../../api/utils';
import {
    getAllProducts,
    getAuthoredScripts,
    SimpleProductResponse,
    ProductStatus,
    updateProduct,
} from '../../api/productsClient';
import { ScriptsFilters } from '../reducers/scriptsReducer';
import { apiRequestFailed } from './actions';
import { RootState } from '../reducers/rootReducer';
import { debounce } from 'lodash';
import { Dispatch } from 'react';
import { getFeaturedScripts } from '../../api/scriptsClient';

export const FETCH_SCRIPTS_REQUESTED = 'FETCH_SCRIPTS_REQUESTED';
export const FETCH_SCRIPTS_COMPLETED = 'FETCH_SCRIPTS_COMPLETED';

export const fetchScriptsRequested = () => ({
    type: FETCH_SCRIPTS_REQUESTED,
});
export const fetchScriptsCompleted = (
    page: PaginatedResponse<SimpleProductResponse>,
    filtered: boolean,
) => ({
    type: FETCH_SCRIPTS_COMPLETED,
    page: page,
    filtered: filtered,
});

export const FETCH_FEATURED_SCRIPTS_REQUESTED =
    'FETCH_FEATURED_SCRIPTS_REQUESTED';
export const FETCH_FEATURED_SCRIPTS_COMPLETED =
    'FETCH_FEATURED_SCRIPTS_COMPLETED';

export const fetchFeaturedScriptsRequested = () => ({
    type: FETCH_FEATURED_SCRIPTS_REQUESTED,
});

export const fetchFeaturedScriptsCompleted = (
    featuredScripts: Array<SimpleProductResponse>,
) => ({
    type: FETCH_FEATURED_SCRIPTS_COMPLETED,
    featuredScripts: featuredScripts,
});

export const fetchFeaturedScripts = (ctx: PageContext | null) => {
    return async (dispatch) => {
        dispatch(fetchFeaturedScriptsRequested());
        try {
            const featuredScripts = await getFeaturedScripts(ctx);
            return dispatch(fetchFeaturedScriptsCompleted(featuredScripts));
        } catch (e) {
            return dispatch(
                apiRequestFailed('Failed to fetch featured scripts', e),
            );
        }
    };
};

export const FETCH_AUTHORED_SCRIPTS_REQUESTED =
    'FETCH_AUTHORED_SCRIPTS_REQUESTED';
export const FETCH_AUTHORED_SCRIPTS_COMPLETED =
    'FETCH_AUTHORED_SCRIPTS_COMPLETED';

export const fetchAuthoredScriptsRequested = () => ({
    type: FETCH_AUTHORED_SCRIPTS_REQUESTED,
});

export const fetchAuthoredScriptsCompleted = (
    authoredScripts: Array<SimpleProductResponse>,
) => ({
    type: FETCH_AUTHORED_SCRIPTS_COMPLETED,
    authoredScripts: authoredScripts,
});

export const fetchAuthoredScripts = (ctx: PageContext | null) => {
    return async (dispatch) => {
        return fetchAuthoredScriptsInternal(dispatch, ctx);
    };
};

async function fetchAuthoredScriptsInternal(
    dispatch: Dispatch<any>,
    ctx: PageContext | null,
) {
    dispatch(fetchAuthoredScriptsRequested());
    try {
        const authoredScripts = await getAuthoredScripts(ctx);
        return dispatch(fetchAuthoredScriptsCompleted(authoredScripts));
    } catch (e) {
        return dispatch(
            apiRequestFailed('Failed to fetch authored scripts', e),
        );
    }
}

async function fetchScriptsInternal(
    dispatch: Dispatch<any>,
    ctx: PageContext | null,
    getState: () => RootState,
    page: number | null = null,
) {
    dispatch(fetchScriptsRequested());
    try {
        const scripts = await getAllProducts(
            ctx,
            null,
            page || getState().scripts.page,
            getState().scripts.filters.category,
            getState().scripts.filters.subscriptionType,
            getState().scripts.filters.text,
            'Script',
            getState().scripts.filters.added,
            getState().scripts.filters.mobileReady || null,
        );

        const isFiltered =
            getState().scripts.filters.category != null ||
            getState().scripts.filters.subscriptionType != null ||
            getState().scripts.filters.text !== '';

        return dispatch(fetchScriptsCompleted(scripts, isFiltered));
    } catch (e) {
        dispatch(apiRequestFailed('Failed to fetch scripts', e));
    }
}

export const fetchScripts = (
    ctx: PageContext | null,
    page: number | null = null,
) => {
    return async (dispatch, getState: () => RootState) => {
        return fetchScriptsInternal(dispatch, ctx, getState, page);
    };
};

export const debouncedFetchScripts = debounce(
    (
        dispatch: Dispatch<any>,
        ctx: PageContext | null,
        getState: () => RootState,
        page: number | null = null,
    ) => fetchScriptsInternal(dispatch, ctx, getState, page),
    500,
);

export const SEARCH_SCRIPTS_REQUESTED = 'SEARCH_SCRIPTS_REQUESTED';
export const SEARCH_SCRIPTS_COMPLETED = 'SEARCH_SCRIPTS_COMPLETED';

export const searchScriptsRequested = (filters: ScriptsFilters) => ({
    type: SEARCH_SCRIPTS_REQUESTED,
    filters,
});
export const searchScriptsCompleted = () => ({
    type: SEARCH_SCRIPTS_COMPLETED,
});

export const searchScripts = (filters: ScriptsFilters) => {
    return async (dispatch, getState: () => RootState) => {
        try {
            await dispatch(searchScriptsRequested(filters));
            await debouncedFetchScripts(dispatch, null, getState, null);
            return dispatch(searchScriptsCompleted());
        } catch (e) {
            dispatch(apiRequestFailed('Failed to search scripts', e));
        }
    };
};

export const UPDATE_SCRIPT_REQUESTED = 'UPDATE_SCRIPT_REQUESTED';
export const UPDATE_SCRIPT_COMPLETED = 'UPDATE_SCRIPT_COMPLETED';

export const updateScriptRequested = () => ({
    type: UPDATE_SCRIPT_REQUESTED,
});
export const updateScriptCompleted = () => ({
    type: UPDATE_SCRIPT_COMPLETED,
});

export const updateScript = (
    script: SimpleProductResponse,
    name: string,
    status: ProductStatus,
) => {
    return async (dispatch) => {
        dispatch(updateScriptRequested());
        try {
            await updateProduct(null, script.id, name, status);
            await fetchAuthoredScriptsInternal(dispatch, null);
            dispatch(updateScriptCompleted());
        } catch (e) {
            dispatch(apiRequestFailed('Failed to update script', e));
        }
    };
};
