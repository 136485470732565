import { BACKEND_API_HOST, fetchApi, PageContext } from './utils';
import { SimpleProductResponse } from './productsClient';

export async function getFeaturedScripts(
    ctx: PageContext | null,
): Promise<Array<SimpleProductResponse>> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/products/scripts/featured`,
        'GET',
        null,
        null,
        false,
    );
}
